import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { ScoresChangeArrowFallingIcon as ScoreDecrease } from '@peakon/bedrock/icons/graphic';

import Card from './';
import { useT } from 'features/i18n/useT';

function BottomDriverCard({ summary }) {
  const t = useT();

  if (!summary.bottomCategory) {
    return null;
  }

  return (
    <Card
      icon={ScoreDecrease}
      title={t('survey__reward__least_satisfied_v2', {
        replace: { driver: summary.bottomCategory.name },
        components: { emphasis: <strong className="is-negative" /> },
      })}
      description={
        <Fragment>
          <strong>{summary.bottomCategory.name}</strong>
          {' – '}
          {summary.bottomCategory.description}
        </Fragment>
      }
      data-test-id="bottom-driver-card"
    />
  );
}

BottomDriverCard.propTypes = {
  summary: PropTypes.object,
};

export default BottomDriverCard;
