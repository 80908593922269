import React from 'react';

import PropTypes from 'prop-types';

import i18n from '../../features/i18n/i18n';

const Text = ({
  className,
  children,
  interpolate = false,
  preRender = (id) => id,
  rawTitle,
  title,
  type = 'span',
  testId,
  values = {},
  source: sourceProp,
  ...props
}) => {
  const getTitle = (title) => {
    if (!title) {
      return;
    }

    try {
      return i18n.t(title);
    } catch (error) {
      return title;
    }
  };

  let value;
  const source = sourceProp ?? children;

  try {
    if (typeof source === 'string') {
      value = i18n.t(source, values);
    } else {
      value = source;
    }
  } catch (error) {
    value = source;
  }

  return React.createElement(
    type,
    {
      className,
      'data-test-id': testId,
      title: rawTitle || getTitle(title),
      ...props,
    },
    preRender(value),
  );
};

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  source: PropTypes.node,
  interpolate: PropTypes.bool,
  preRender: PropTypes.func,
  rawTitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  testId: PropTypes.string,
  values: PropTypes.object,
};

export default Text;
