import React, { useState } from 'react';

import { SystemBanner } from 'components';
import PropTypes from 'prop-types';

import { EditCloseIcon as CloseIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';

import styles from './Survey.scss';
import { useT } from 'features/i18n/useT';

function getBannerContent(isSkipped, handlePreviewThankYouPage, t) {
  if (isSkipped || window.location.pathname.includes('/thankyou')) {
    return t('survey__submit__preview__skipped_v3', {
      components: {
        emphasis: <strong />,
      },
    });
  } else {
    return t('survey__submit__preview__skip_v3', {
      components: {
        emphasis: <strong />,
        link: (
          <a
            href="#"
            className="peak-link"
            onClick={handlePreviewThankYouPage}
          />
        ),
      },
    });
  }
}

function PreviewBanner({ isSkipped, handlePreviewThankYouPage }) {
  const t = useT();
  const [closed, setClosed] = useState(false);

  if (closed) {
    return null;
  }

  return (
    <SystemBanner position="bottom">
      {getBannerContent(isSkipped, handlePreviewThankYouPage, t)}
      <UnstyledButton
        className={styles.previewClose}
        onClick={() => setClosed(true)}
        accessibleName={t('common_close')}
      >
        <CloseIcon />
      </UnstyledButton>
    </SystemBanner>
  );
}

PreviewBanner.propTypes = {
  handlePreviewThankYouPage: PropTypes.func,
  isSkipped: PropTypes.bool,
};

export default PreviewBanner;
