import React from 'react';

import classnames from 'classnames';
import useIsEligibleForResearchPanel from 'hooks/useIsEligibleForResearchPanel';
import { List } from 'immutable';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';

import { LinkButton } from '@peakon/bedrock/react/button';

import getWebHost from 'utils/getWebHost';

import DoneIllustration from './done-illustration.png';
import EmployeeDashboardImage from './employee-dashboard@2x.png';
import EmptyStateImage from './empty-state@2x.png';
import ResearchPanel from '../ResearchPanel';
import styles from '../styles.scss';
import { useT } from 'features/i18n/useT';

function useNextSurveyDays(date) {
  if (!date) {
    return null;
  }
  if (!moment(date).isValid()) {
    return null;
  }
  return Math.abs(moment().diff(date, 'days'));
}

function Sidebar({ session, summary, addOns, locale, actions }) {
  const t = useT();

  const hasEmployeeDashboardRights = get(session, 'rights', []).includes(
    'employeeDashboard:access',
  );

  const isEligibleForResearchPanel = useIsEligibleForResearchPanel(
    summary,
    addOns,
    locale,
  );

  let imgSrc = !hasEmployeeDashboardRights
    ? EmptyStateImage
    : EmployeeDashboardImage;

  if (!summary.enabled) {
    imgSrc = DoneIllustration;
  }

  const nextSurveyDays = useNextSurveyDays(summary.nextStart);

  return (
    <div className={styles.sidebar}>
      {summary.enabled && nextSurveyDays && (
        <div className={styles.nextSurvey}>
          <span data-test-id="survey-next-date">
            {t('employee_dashboard__v2__next_survey_icu_v2', {
              replace: { days: nextSurveyDays },
              components: { emphasis: <strong /> },
            })}
          </span>
          <LinkButton
            variant="secondary"
            size="small"
            href={`${getWebHost()}/what-is-peakon`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('survey__reward__learn_more_about_peakon_v2')}
          </LinkButton>
        </div>
      )}
      <div className={styles.imageContainer}>
        <img
          src={imgSrc}
          className={classnames({
            [styles.smallImage]: !hasEmployeeDashboardRights,
          })}
          alt=""
        />
      </div>

      {isEligibleForResearchPanel && <ResearchPanel actions={actions} />}
    </div>
  );
}

Sidebar.propTypes = {
  session: PropTypes.object,
  summary: PropTypes.object,
  addOns: PropTypes.instanceOf(List),
  locale: PropTypes.string,
  actions: PropTypes.object,
};

export default Sidebar;
