import React, { useState, useEffect, useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Portal from 'components/Portal';

import styles from './KioskStopOverlay.scss';

import get from 'lodash/get';
import { useT } from 'features/i18n/useT';
const Mousetrap = require('mousetrap-pause')(require('mousetrap'));
const TIMEOUT = get(ENV, 'timeouts.kioskStopOverlayAutoLogoutSeconds') || 10;

const KioskStopOverlay = ({ onContinue, onStop }) => {
  const [seconds, setSeconds] = useState(TIMEOUT);
  const t = useT();
  const intervalRef = useRef();

  if (seconds - 1 < 1) {
    clearInterval(intervalRef.current);
    onStop();
  }

  useEffect(() => {
    Mousetrap.pause();

    const rootNode = window.document.getElementById('survey-app');

    if (rootNode) {
      rootNode.classList.add('has-overlay');
      rootNode.setAttribute('aria-hidden', true);
    }

    intervalRef.current = setInterval(
      () => setSeconds((value) => value - 1),
      1000,
    );

    return () => {
      const rootNode = window.document.getElementById('survey-app');

      if (rootNode) {
        rootNode.classList.remove('has-overlay');
        rootNode.setAttribute('aria-hidden', false);
      }

      clearInterval(intervalRef.current);
      Mousetrap.unpause();
    };
  }, []);

  return (
    <Portal>
      <div className={styles.root}>
        <div>
          <h2 className={styles.title}>{t('survey__stop_survey__title')}</h2>
          {t('survey__stop_survey__text__seconds__v3', {
            replace: {
              numberOfSeconds: seconds,
            },
            components: {
              emphasis: <strong />,
            },
          })}
          <div className={styles.actions}>
            <a
              className={cx('peak-btn', 'peak-btn--lg', styles.buttonContinue)}
              onClick={onContinue}
              data-test-id="continue-survey-btn"
            >
              {t('survey__stop_survey__continue')}
            </a>

            <a
              className="peak-btn peak-btn-warning peak-btn--lg"
              onClick={onStop}
              data-test-id="stop-survey-btn"
            >
              <span>{t('survey__stop_survey')}</span>
            </a>
          </div>
        </div>
      </div>
    </Portal>
  );
};

KioskStopOverlay.propTypes = {
  onContinue: PropTypes.func,
  onStop: PropTypes.func,
};

export default KioskStopOverlay;
